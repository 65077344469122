<template>
  <div class="container">
    <div class="mx-auto loginContent">
      <div class="bread-crumb my-3">
        <a
          class="text-primary"
          href="javascript:void(0);"
          @click="$router.push('/').catch(() => {})"
          >HOME</a
        >
        > CONFIRM ACCOUNT
      </div>
      <div class="card text-center">
        <div class="card-body">
          <h2 class="text-primary">Confirm Your Account</h2>
          <hr />
          <FormErrorAlert
            v-if="errors.length > 0"
            :errors="errors"
            :formRef="this.$refs.accountConfirmForm"
          />
          <ValidationObserver ref="accountConfirmForm">
            <form @submit.prevent="onSubmit">
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|min:8|atLeastOneLowercase|atLeastOneUppercase|atLeastOneNumber|atLeastOneSpecialCharacter"
                    name="Password"
                    id="confirmPassword1"
                    v-model="confirmPassword1"
                    vid="pass"
                    type="password"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <div class="form-row justify-content-center">
                <div
                  class="col col-lg-6 col-md-6 col-sm-12 col-12 form-group text-left"
                >
                  <TextInput
                    rules="required|confirmed:pass"
                    name="Confirm Password"
                    id="confirmPassword2"
                    v-model="confirmPassword2"
                    type="password"
                    placeholder="Confirm your password"
                  />
                </div>
              </div>
              <button type="submit" class="btn btn-primary">
                CONFIRM ACCOUNT
              </button>
              <p class="mt-3">
                <span style="color: red;">*</span> Indicates a required field
              </p>
              <p class="mt-3">
                Taking too long? Click below to resend your confirmation email
                (the previous link will be invalid).
              </p>
              <button
                type="button"
                @click="onResendCode"
                class="btn btn-primary"
              >
                RESEND LINK
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import ProfileService from "@/services/ProfileService.js";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
export default {
  name: "CreateAccountConfirm",
  title: "Create Account Confirm",
  data() {
    return {
      confirmPassword1: "",
      confirmPassword2: "",
      errors: []
    };
  },
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert
  },
  methods: {
    async onSubmit() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const request = {
        userId: +this.$route.query.userId,
        password: this.confirmPassword1,
        confirmPassword: this.confirmPassword2,
        confirmationCode: this.$route.query.code
      };
      this.$refs.accountConfirmForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.accountConfirmForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.accountConfirmForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new ProfileService(tenantId);
            const response = await profileService.confirmUserRegistration(
              request
            );
            if (response && response.user) {
              this.handleConfirmedUser(response.user);
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message:
                  "User account creation was not confirmed. Link may have expired.",
                layer: "public"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "public"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    async onResendCode() {
      try {
        this.$store.commit("auth/setLoading", true);
        const tenantId = this.$store.getters["tenant/tenantId"];
        const profileService = new ProfileService(tenantId);
        await profileService.resendRegistrationCode(this.$route.query.userId);
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-info",
          message:
            "Account confirmation link sent. Any previous links will not be valid.",
          layer: "public"
        });
      } catch (err) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "public"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    async handleConfirmedUser(confirmedUser) {
      await this.$store.dispatch("auth/login", {
        loginUsername: confirmedUser.username,
        loginPassword: this.confirmPassword1,
        authSource: "local"
      });
      if (this.$store.getters["transaction/reservationInProgress"]) {
        this.$router.push("/create-reservation").catch(() => {});
      }
    },
    async checkUserVerification() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      const profileService = new ProfileService(tenantId);
      const userIsVerified = await profileService.userVerification(
        this.$route.query.userId
      );
      if (userIsVerified) {
        this.$router.push("/login").catch(() => {});
      }
    }
  },
  created() {
    this.checkUserVerification();
  }
};
</script>

<style scoped>
.card-body {
  margin-bottom: 90px;
}
</style>
